import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Card from "../../Utils/Card/Card";
import styles from "./_signup.module.scss";
import { AuthActions } from "../../../Store/auth-slice";
import Error from "../../Modal/Error/Error";
import { backDropActions } from "../../../Store/backdrop-slice.js";
import googleIcon from "../../../Images/google.svg";
import fluteIcon from "../../../Images/flute.png";
import { Mail } from "lucide-react";

const Signup = () => {
  const inputName = useRef();
  const inputPassword = useRef();
  const inputEmail = useRef();
  const confirmPassword = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.Auth);
  const [error, setError] = useState({ isError: false, errorMessage: "" });
  const backDrop = useSelector((state) => state.BackDrop);
  const [isFirst, setIsFirst] = useState(false);

  useEffect(() => {
    dispatch(backDropActions.backDropHandler(true));
  }, [dispatch]);

  useEffect(() => {
    if (!isFirst) {
      setIsFirst(true);
    }
    if (isFirst && !backDrop.backDrop) {
      history.push("/");
    }
  }, [backDrop, isFirst, history]);

  const googleAuthHandler = async (event) => {
    window.open(`${process.env.REACT_APP_SERVER_DOMAIN}/auth/google`, "_self");
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    dispatch(AuthActions.loading(true));
    const inputUserName = inputName.current.value;
    const inputUserEmail = inputEmail.current.value;
    const inputUserPassword = inputPassword.current.value;
    const inputUserConfirmPassword = confirmPassword.current.value;

    const user = {
      name: inputUserName,
      email: inputUserEmail,
      password: inputUserPassword,
      confirmPassword: inputUserConfirmPassword,
    };
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_DOMAIN}/auth/signup`, //"http://localhost:3000/user/signup",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      }
    );
    dispatch(AuthActions.loading(false));
    if (!response.ok) {
      const resData = await response.json();
      setError({ isError: true, errorMessage: resData.message });
      return;
    }
    history.push("/login");
  };
  return (
    <Card className={styles.card_login}>
      <div className={styles.profile_pic}>
        <div>
          <h1 className={styles.heading2}>Create New Account</h1>
          <div className={styles.iconContainer_flute}>
            <img src={fluteIcon} alt="fluteIcon" />
          </div>
          <p className={styles.par}>
            To get started you can signup with email and google
          </p>
        </div>
        <div>
          <div
            className={`${styles.iconContainer} ${styles.iconContainer_email}`}
            onClick={googleAuthHandler}
          >
            <div className={styles.iconContainer_google}>
              <Mail />
            </div>
            <p>Sign up with Email</p>
          </div>
          <div className={styles.iconContainer} onClick={googleAuthHandler}>
            <div className={styles.iconContainer_google}>
              <img src={googleIcon} alt="google" />
            </div>
            <p>Sign up with Google</p>
          </div>
        </div>
        <div className={styles.account}>
          <h2>Already have an Account?</h2>
          <button
            className={`${styles.btn} ${styles.account_btn}`}
            onClick={() => {
              history.push("/login");
            }}
          >
            Login
          </button>
        </div>
      </div>
      <div className={styles.for_signup}>
        {error.isError && (
          <Error message={error.errorMessage.toUpperCase()}></Error>
        )}
        <div className={styles.heading}>
          <h1>Register with Email</h1>
        </div>
        <form
          className={styles.login_form}
          action="/signup"
          encType="multipart/form-data"
        >
          <div className={styles.form_control}>
            <label htmlFor="Name">Name</label>
            <input
              ref={inputName}
              type="text"
              name="Name"
              id="Name"
              placeholder="Username"
            />
          </div>
          <div className={styles.form_control}>
            <label htmlFor="email">E-mail</label>
            <input
              ref={inputEmail}
              type="email"
              name="email"
              id="email"
              placeholder="Email Address"
            />
          </div>
          <div className={styles.form_control}>
            <label htmlFor="password">Password</label>
            <input
              ref={inputPassword}
              type="password"
              name="password"
              id="password"
              placeholder="Password"
            />
          </div>
          <div className={styles.form_control}>
            <label htmlFor="confirm">Confirm password</label>
            <input
              ref={confirmPassword}
              type="password"
              name="confirm password"
              id="confirm"
              placeholder="confirm password"
            />
          </div>
          {!auth.authLoading && (
            <button
              onClick={submitHandler}
              className={styles.btn}
              type="submit"
            >
              Signup
            </button>
          )}
          {auth.authLoading && (
            <button
              onClick={(e) => {
                e.preventDefault();
              }}
              className={styles.btn}
            >
              Loading...
            </button>
          )}
        </form>
      </div>
    </Card>
  );
};

export default Signup;
