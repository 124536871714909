import Card from "../../../Utils/Card/Card";
import styles from "./_productCard.module.scss";
import img from "../../../../Images/flute.png";
import { Plus, Minus } from "lucide-react";
import { useState } from "react";
import Button from "../../../Utils/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { cartTrunk } from "../../../../Store/cart-action";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { calculateDiscountPercentage } from "../../../../Helpers/discountPercentage";
import { toastifyWarning } from "../../../../Helpers/notificationToastify";
import images from "../../../../Helpers/importColorImages";
import { Brush, Undo2 } from "lucide-react";
import { dotWave } from "ldrs";

dotWave.register();

const ProductCard = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [quantity, setQuantity] = useState(props.quantity || 1);
  const [hand, setHand] = useState(props.hand || 0);
  const [finger, setFinger] = useState(props.finger || 0);
  const [color, setColor] = useState(props.color || 0);
  const dispatch = useDispatch();
  const cartState = useSelector((state) => state.CartSlice);
  const authState = useSelector((state) => state.Auth);
  const history = useHistory();
  const [isFlipped, setIsFlipped] = useState(false);
  const imageKeys = Object.keys(images);
  const [moreColor, setMoreColor] = useState(false);

  const flipCard = () => {
    setIsFlipped(!isFlipped); // Toggle the flip state
  };

  const discountPercentage = calculateDiscountPercentage(
    props.product.price,
    props.product.discount
  );

  const discountPrice = props.product.price - props.product.discount;

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const increaseQuantity = () => {
    if (quantity < 10) {
      setQuantity(quantity + 1);
    }
  };

  const addToCartHandler = () => {
    if (!authState.isLoggedIn || !authState.token) {
      toastifyWarning(
        "SignUp or LogIn for exclusive flute offers and updates.",
        "🎵"
      );
      history.push("/login");
    } else {
      const token = authState.token;
      dispatch(
        cartTrunk(token, quantity, "add", props.id, hand, finger, color)
      );
      flipCard();
    }
  };

  return (
    <Card
      className={styles.productCard}
      onClick={() => {
        if (moreColor) {
          setMoreColor(false);
        }
      }}
    >
      <div className={`${styles.card} ${isFlipped ? styles.flipped : ""}`}>
        <div className={styles.front}>
          <div className={styles.imgContainer} onClick={props.onClick}>
            <img
              src={props.product?.images?.[0]?.image}
              onError={img}
              alt="flute"
            />
          </div>
          <div>
            <h2 className={styles.h2}>
              {props.product.heading ||
                "A Natural Base FryBamboo Flute 23 Inch (58 cm)"}
            </h2>
            <div className={styles.productCard_top_content_price}>
              <span className={styles.productCard_top_content_price_new}>
                ₹{discountPrice || 4500}
              </span>
              <span className={styles.productCard_top_content_price_old}>
                ₹{props.product.price || 4500}
              </span>
              <div className={styles.productCard_top_content_price_discount}>
                <p>{discountPercentage || "43"}% OFF</p>
              </div>
            </div>
            <div className={styles.productCard_bottom}>
              {!cartState.cart[props.id] && (
                <div className={styles.productCard_bottom_container}>
                  <Minus onClick={decreaseQuantity} className={styles.icon} />
                  <span className={styles.quantity}>{quantity}</span>
                  <Plus onClick={increaseQuantity} className={styles.icon} />
                </div>
              )}

              {!cartState.cart[props.id] &&
                (!cartState.cartLoading ? (
                  <Button
                    className={styles.btn}
                    onClick={() => {
                      flipCard();
                      setIsHovered(true);
                    }}
                  >
                    Add To Cart
                  </Button>
                ) : (
                  <Button
                    className={styles.btn}
                    onMouseEnter={() => {
                      setIsHovered(true);
                    }}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <l-dot-wave
                      size="35"
                      speed="2.5"
                      color={isHovered ? "#e14d2a" : "white"}
                    ></l-dot-wave>
                  </Button>
                ))}
              {cartState.cart[props.id] && (
                <Button
                  className={`${styles.btn} ${styles.btn_goToCart}`}
                  onClick={() => {
                    history.push("/cart");
                  }}
                >
                  Go To Cart
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className={styles.back}>
          <h1 className={styles.back_title}>Features Customization</h1>
          <div className={styles.back_feature}>
            <p>Quantity : </p>
            {!cartState.cart[props.id] && (
              <div
                className={`${styles.productCard_bottom_container} ${styles.back_feature_quantity}`}
              >
                <Minus onClick={decreaseQuantity} className={styles.icon} />
                <span className={styles.quantity}>{quantity}</span>
                <Plus onClick={increaseQuantity} className={styles.icon} />
              </div>
            )}
          </div>
          {props.product.type === "flute" && (
            <form className={styles.back_feature}>
              <p>Hand: </p>
              <div className={styles.back_feature_container}>
                <input
                  type="radio"
                  id="leftRadio"
                  name="hand"
                  value={0}
                  onChange={() => {
                    setHand(0);
                  }}
                  checked={hand === 0}
                />
                <label htmlFor="leftRadio">Left flute</label>
              </div>
              <div className={styles.back_feature_container}>
                <input
                  type="radio"
                  id="rightRadio"
                  name="hand"
                  value={1}
                  onChange={() => {
                    setHand(1);
                  }}
                  checked={hand === 1}
                />
                <label htmlFor="rightRadio">Right flute</label>
              </div>
            </form>
          )}

          <form style={{ display: "none" }} className={styles.back_feature}>
            <p>Finger Hole: </p>
            <div className={styles.back_feature_container}>
              <input
                type="radio"
                id="customized"
                name="finger"
                value={0}
                checked={finger === 0}
                onChange={() => {
                  setFinger(0);
                }}
              />
              <label htmlFor="customized">Customized</label>
            </div>
            <div className={styles.back_feature_container}>
              <input
                type="radio"
                id="regular"
                name="finger"
                value={1}
                checked={finger === 1}
                onChange={() => {
                  setFinger(1);
                }}
              />
              <label htmlFor="regular">Regular</label>
            </div>
          </form>
          <div style={{ display: "none" }} className={styles.back_feature}>
            <p>Color:</p>
            <div className={styles.color_choose}>
              <div className={styles.wrapper}>
                <div className={styles.color_choose_container}>
                  <img
                    value={color}
                    src={images[imageKeys[color]]}
                    alt={`T-${color + 1}`}
                    width={45}
                    className={styles.selected}
                  ></img>
                  <span>T-{color + 1}</span>
                </div>
                <div className={styles.color_choose_container}>
                  <img
                    src={
                      color === imageKeys.length - 1
                        ? images[imageKeys[color - 1]]
                        : images[imageKeys[color + 1]]
                    }
                    alt="T-2"
                    width={45}
                    onClick={() => {
                      setColor(
                        color === imageKeys.length - 1 ? color + 1 : color + 1
                      );
                    }}
                  ></img>
                  <span>
                    T-
                    {color === imageKeys.length - 1 ? color : color + 2}
                  </span>
                </div>
              </div>
            </div>
            <Button
              className={`${styles.btn} ${styles.color_btn}`}
              onClick={() => {
                setMoreColor(true);
              }}
            >
              <Brush style={{ width: "1rem", marginRight: "5px" }} /> More
            </Button>
          </div>
          <div className={styles.addToCart}>
            {!cartState.cart[props.id] &&
              (!cartState.cartLoading ? (
                <Button
                  className={styles.btn}
                  onClick={() => {
                    addToCartHandler();
                    setIsHovered(true);
                  }}
                >
                  Add To Cart
                </Button>
              ) : (
                <Button
                  className={styles.btn}
                  onMouseEnter={() => {
                    setIsHovered(true);
                  }}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <l-dot-wave
                    size="35"
                    speed="2.5"
                    color={isHovered ? "#e14d2a" : "white"}
                  ></l-dot-wave>
                </Button>
              ))}
            {cartState.cart[props.id] && (
              <Button
                className={`${styles.btn} ${styles.btn_goToCart}`}
                onClick={() => {
                  history.push("/cart");
                }}
              >
                Go To Cart
              </Button>
            )}
          </div>
          <div
            className={`${styles.color_more} ${
              moreColor ? styles.color_more_visual : ""
            }`}
          >
            <div className={styles.color_more_wrapper}>
              {imageKeys.map((image, index) => {
                return (
                  <div className={styles.color_choose_container} key={index}>
                    <img
                      key={index}
                      src={images[image]}
                      alt={`T-${index + 1}`}
                      width={60}
                      onClick={() => {
                        setColor(index);
                      }}
                    />
                    <span>T-{index + 1}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.turn} onClick={flipCard}>
            <Undo2 />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ProductCard;
