import { toast } from "react-toastify";

const notificationStyle = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

export const toastifySuccess = (message, icon = "") => {
  if (icon) {
    notificationStyle.icon = icon;
  }
  toast.success(message, notificationStyle);
};

export const toastifyWarning = (message, icon = "") => {
  if (icon) {
    notificationStyle.icon = icon;
  }
  notificationStyle.theme = "light";
  notificationStyle.autoClose = 5000;
  toast.warn(message, notificationStyle);
};

export const toastifyError = (message, icon = "") => {
  if (icon) {
    notificationStyle.icon = icon;
  }
  toast.error(message, notificationStyle);
};
