import React from 'react'

function RefundPolicy() {
  return (
    <div>
      <h1>Refund Policy:</h1>
      <p>Our refund policy means we donot offer refund or return on any order. Still you get some query on that please contact deepaksoniflute@gmail.com</p>
      <h2>Eligibility for Refunds:</h2>
      <p>We offer no refunds </p>
      <h2>How to Request a Refund:</h2>
      <p>To request a query, simply email (deepaksoniflute@gmail.com).</p>
      <h2>Contact Us:</h2>
      <p>If you have any questions about our refund policy, please contact us at: deepaksoniflute@gmail.com</p>
    </div>
  )
}

export default RefundPolicy