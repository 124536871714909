import React, { useEffect, useState } from "react";
import styles from "./_header.module.scss";
import Nav from "./Nav";
import { backDropActions } from "../../../Store/backdrop-slice";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import LogoMah from "../../../Images/deepakFlute_logo_white.png";
import { AlignJustify } from "lucide-react";

const Header = ({ salesDetails }) => {
  const dispatch = useDispatch();
  const [isTransparent, setIsTransparent] = useState(true);
  const location = useLocation();
  const currentURL = location.pathname;

  useEffect(() => {
    // Add a scroll event listener to the window
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Check the scroll position (adjust the threshold as needed)
    if (window.scrollY > 70) {
      setIsTransparent(false);
    } else {
      setIsTransparent(true);
    }
  };
  return (
    <header
      className={`${styles.header} ${
        isTransparent
          ? currentURL === "/"
            ? styles.transparent
            : styles.main
          : ""
      }`}
    >
      <NavLink to="/">
        <div className={styles.logo}>
          <img src={LogoMah} alt="react-timeLogo" />
        </div>
      </NavLink>

      <div
        onClick={() => {
          dispatch(backDropActions.mobileNavHandler(true));
        }}
        className={styles.logo_menu}
      >
        <AlignJustify
          style={{
            width: "2.5rem",
            height: "2.5rem",
            color: `${isTransparent && currentURL === "/" ? "white" : "white"}`,
          }}
        />
      </div>

      <Nav salesDetails={salesDetails} className={styles.hidden}></Nav>
    </header>
  );
};

export default Header;
