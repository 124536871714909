import { useEffect, useState } from "react";
import styles from "./_productDetails.module.scss";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Utils/button/Button";
import SectionCarousel from "../../FeatureComponents/SectionCarousel/SectionCarousel";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { cartTrunk } from "../../../Store/cart-action";
import { filterTrunk } from "../../../Store/filter-action";
import { calculateDiscountPercentage } from "../../../Helpers/discountPercentage";
import {
  toastifyError,
  toastifyWarning,
} from "../../../Helpers/notificationToastify";
import images from "../../../Helpers/importColorImages";
import { Brush, Plus, Minus } from "lucide-react";
import { dotWave, momentum, waveform, square, bouncy } from "ldrs";
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import ImageCarousel from "../../../Helpers/imageCarousel";
import { ProductAction } from "../../../Store/product-slice";

bouncy.register();
momentum.register();
dotWave.register();
waveform.register();
square.register();

const ProductDetails = () => {
  const [quantity, setQuantity] = useState(1);
  const [hand, setHand] = useState(0);
  const [finger, setFinger] = useState(0);
  const [color, setColor] = useState(0);
  const [bag, setBag] = useState(0);
  const [moreColor, setMoreColor] = useState(false);
  const imageKeys = Object.keys(images);
  const cartState = useSelector((state) => state.CartSlice);
  const history = useHistory();
  const authState = useSelector((state) => state.Auth);
  const [isHovered, setIsHovered] = useState(false);

  const [currentProduct, setCurrentProduct] = useState({
    heading: "",
    description: "",
    price: "",
    discount: "",
    materialType: "",
    soundType: "",
    scaleType: "",
    imageUrl: "",
    oldPrice: "",
    images: [],
    soundDemo: "",
    fluteDemo: "",
    productType: "",
  });
  const params = useParams();
  const productState = useSelector((state) => state.ProductSlice);
  const dispatch = useDispatch();

  const UpdateCartHandler = () => {
    if (!authState.isLoggedIn || !authState.token) {
      toastifyWarning(
        "SignUp or LogIn for exclusive flute offers and updates.",
        "🎵"
      );
      history.push("/login");
    } else {
      const token = authState.token;
      dispatch(
        cartTrunk(
          token,
          quantity,
          "update",
          params.productId,
          hand,
          finger,
          color,
          bag,
          imageKeys.length
        )
      );
    }
  };

  const addToCartHandler = () => {
    if (authState.isLoggedIn && authState.token) {
      const token = authState.token;
      dispatch(
        cartTrunk(
          token,
          quantity,
          "add",
          params.productId,
          hand,
          finger,
          color,
          bag,
          imageKeys.length
        )
      );
    } else {
      toastifyWarning(
        "SignUp or LogIn for exclusive flute offers and updates.",
        "🎵"
      );
      history.push("/login");
    }
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const increaseQuantity = () => {
    if (quantity < 10) {
      setQuantity(quantity + 1);
    }
  };

  useEffect(() => {
    const getProductById = async () => {
      try {
        dispatch(ProductAction.productLoading(true));
        if (params.productId) {
          console.log("product fetched by Id fun running....");
          const productId = params.productId;
          if (!productId) {
            throw new Error("No Product Found");
          }
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_DOMAIN}/getInfo/product`,
            {
              method: "POST",
              body: JSON.stringify({ productId: productId }),
              headers: {
                "content-type": "application/json",
                Accept: "application/json",
              },
            }
          );
          const resData = await response.json();
          if (!response.ok) {
            throw new Error(resData.message);
          }
          console.log(resData);
          const givenProduct = resData.productData;
          setCurrentProduct({
            heading: givenProduct[0].heading,
            description: givenProduct[0].description,
            price: givenProduct[0].price,
            discount: calculateDiscountPercentage(
              givenProduct[0].price,
              givenProduct[0].discount
            ),
            materialType: givenProduct[0].materialType,
            soundType: givenProduct[0].soundType,
            scaleType: givenProduct[0].scaleType,
            imageUrl: givenProduct[0].imageUrl,
            images: givenProduct[0].images,
            soundDemo: givenProduct[0].soundDemo,
            fluteDemo: givenProduct[0].fluteDemo,
            discountPrice: givenProduct[0].price - givenProduct[0].discount,
            productType: givenProduct[0].type,
          });
          scrollToTop();
        }
      } catch (error) {
        toastifyError("Product Not Found");
        history.push("/");
      } finally {
        dispatch(ProductAction.productLoading(false));
      }
    };
    getProductById();
  }, [history, params.productId, dispatch]);

  useEffect(() => {
    if (
      authState.isLoggedIn &&
      authState.token &&
      cartState.isCart &&
      cartState.cart[params.productId]
    ) {
      setQuantity(cartState.cart[params.productId].quantity);
      setColor(cartState.cart[params.productId].color);
      setFinger(cartState.cart[params.productId].finger);
      setHand(cartState.cart[params.productId].hand);
      setBag(cartState.cart[params.productId].bag);
    } else {
    }
  }, [
    cartState.cartLoading,
    authState.isLoggedIn,
    authState.token,
    cartState.isCart,
    cartState.cart,
    params.productId,
  ]);

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const shareUrl = window.location.href;
  const title = "Deepak Flute";

  return (
    <div>
      {productState.productLoading && (
        <div className={styles.error_container}>
          <span>Loading </span>
          <l-bouncy size="45" speed="1.75" color="#e14d2a"></l-bouncy>
        </div>
      )}
      {!productState.productLoading && (
        <div
          onClick={() => {
            if (moreColor) {
              setMoreColor(false);
            }
          }}
        >
          <div className={styles.productDetails}>
            <div className={styles.productDetails_image}>
              <ImageCarousel images={currentProduct.images} />

              <div className={styles.quantity_container}>
                <h2>Quantity</h2>

                <div className={styles.productCard_bottom_container}>
                  <Minus onClick={decreaseQuantity} className={styles.icon} />
                  <span className={styles.quantity}>{quantity}</span>
                  <Plus onClick={increaseQuantity} className={styles.icon} />
                </div>
              </div>

              {!cartState.cart[params.productId] &&
                (!cartState.cartLoading ? (
                  <Button
                    className={styles.btn}
                    onClick={() => {
                      addToCartHandler();
                      setIsHovered(true);
                    }}
                  >
                    Add To Cart
                  </Button>
                ) : (
                  <Button
                    className={styles.btn}
                    onMouseEnter={() => {
                      setIsHovered(true);
                    }}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <l-dot-wave
                      size="35"
                      speed="2.5"
                      color={isHovered ? "#e14d2a" : "white"}
                    ></l-dot-wave>
                  </Button>
                ))}
              {cartState.cart[params.productId] && (
                <Button
                  className={`${styles.btn} ${styles.btn_goToCart}`}
                  onClick={() => {
                    history.push("/cart");
                  }}
                >
                  Go To Cart
                </Button>
              )}
              {cartState.cart[params.productId] &&
                (!cartState.cartLoading ? (
                  <Button
                    className={styles.btn}
                    onClick={() => {
                      UpdateCartHandler();
                      setIsHovered(true);
                    }}
                  >
                    Update Cart
                  </Button>
                ) : (
                  <Button
                    className={styles.btn}
                    onMouseEnter={() => {
                      setIsHovered(true);
                    }}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <l-dot-wave
                      size="35"
                      speed="2.5"
                      color={isHovered ? "#e14d2a" : "white"}
                    ></l-dot-wave>
                  </Button>
                ))}
            </div>
            <div className={styles.productDetails_container}>
              <h1 className={styles.productDetails_h1}>
                {currentProduct.heading || "heading"}
              </h1>
              <div
                style={{ display: currentProduct.soundDemo ? "block" : "none" }}
                className={styles.productDetails_container_content}
              >
                <h2 className={styles.productDetails_h2}>Sound Quality:</h2>
                <div>
                  <iframe
                    width="100%"
                    height="200"
                    src={`${
                      currentProduct.soundDemo ? currentProduct.soundDemo : ""
                    }`}
                    title="Deepak Flutes"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              </div>
              <div
                style={{ display: currentProduct.fluteDemo ? "block" : "none" }}
                className={styles.productDetails_container_content}
              >
                <h2 className={styles.productDetails_h2}>Flute Description:</h2>
                <div>
                  <iframe
                    width="100%"
                    height="200"
                    src={`${
                      currentProduct.fluteDemo ? currentProduct.fluteDemo : ""
                    }`}
                    title="Deepak Flutes"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              </div>
              <div className={styles.productDetails_container_content}>
                <h2 className={styles.productDetails_h2}>Price:</h2>
                <div className={styles.productDetails_container_content_price}>
                  <span
                    className={
                      styles.productDetails_container_content_price_new
                    }
                  >
                    ₹{currentProduct.discountPrice || 4500}
                  </span>
                  <span
                    className={
                      styles.productDetails_container_content_price_old
                    }
                  >
                    ₹{currentProduct.price || 4500}
                  </span>
                  <div
                    className={
                      styles.productDetails_container_content_price_discount
                    }
                  >
                    <p>{currentProduct.discount || "43"}% OFF</p>
                  </div>
                </div>
              </div>
              {currentProduct.productType !== "Bag" && (
                <div className={styles.productDetails_container_content}>
                  <form className={styles.back_feature}>
                    <h2>Hand </h2>
                    <div className={styles.back_feature_container}>
                      <input
                        type="radio"
                        id="leftRadio"
                        name="hand"
                        value={0}
                        onChange={() => {
                          setHand(0);
                        }}
                        checked={hand === 0}
                      />

                      <label htmlFor="leftRadio">Left flute</label>
                    </div>
                    <div className={styles.back_feature_container}>
                      <input
                        type="radio"
                        id="rightRadio"
                        name="hand"
                        value={1}
                        onChange={() => {
                          setHand(1);
                        }}
                        checked={hand === 1}
                      />

                      <label htmlFor="rightRadio">Right flute</label>
                    </div>
                  </form>
                </div>
              )}
              <div
                style={{ display: "none" }}
                className={styles.productDetails_container_content}
              >
                <form className={styles.back_feature}>
                  <h2>Finger Hole</h2>
                  <div className={styles.back_feature_container}>
                    <input
                      type="radio"
                      id="customized"
                      name="finger"
                      value={0}
                      checked={finger === 0}
                      onChange={() => {
                        setFinger(0);
                      }}
                    />

                    <label htmlFor="customized">Customized</label>
                  </div>
                  <div className={styles.back_feature_container}>
                    <input
                      type="radio"
                      id="regular"
                      name="finger"
                      value={1}
                      checked={finger === 1}
                      onChange={() => {
                        setFinger(1);
                      }}
                    />

                    <label htmlFor="regular">Regular</label>
                  </div>
                </form>
              </div>
              {currentProduct.productType !== "Bag" && (
                <div className={styles.productDetails_container_content}>
                  <form className={styles.back_feature}>
                    <h2>Bag:</h2>
                    <div className={styles.back_feature_container}>
                      <input
                        type="radio"
                        id="without"
                        name="bag"
                        value={0}
                        checked={bag === 0}
                        onChange={() => {
                          setBag(0);
                        }}
                      />

                      <label htmlFor="without">Without</label>
                    </div>
                    <div className={styles.back_feature_container}>
                      <input
                        type="radio"
                        id="included"
                        name="bag"
                        value={1}
                        checked={bag === 1}
                        onChange={() => {
                          setBag(1);
                        }}
                      />
                      <label htmlFor="included">Include</label>
                    </div>
                  </form>
                </div>
              )}

              <div
                style={{ display: "none" }}
                className={styles.productDetails_container_content}
              >
                <div className={styles.back_feature}>
                  <h2>Color</h2>
                  <div className={styles.color_choose}>
                    <div className={styles.wrapper}>
                      <div className={styles.color_choose_container}>
                        <img
                          value={color}
                          src={images[imageKeys[color]]}
                          alt={`T-${color + 1}`}
                          width={55}
                          className={styles.selected}
                        ></img>
                        <span>T-{color + 1}</span>
                      </div>
                      <div className={styles.color_choose_container}>
                        <img
                          src={
                            color === imageKeys.length - 1
                              ? images[imageKeys[color - 1]]
                              : images[imageKeys[color + 1]]
                          }
                          alt="T-2"
                          width={55}
                          onClick={() => {
                            setColor(
                              color === imageKeys.length - 1
                                ? color + 1
                                : color + 1
                            );
                          }}
                        ></img>
                        <span>
                          T-
                          {color === imageKeys.length - 1 ? color : color + 2}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Button
                    className={`${styles.btn} ${styles.color_btn}`}
                    onClick={() => {
                      setMoreColor(true);
                    }}
                  >
                    <Brush style={{ width: "1rem", marginRight: "5px" }} /> More
                  </Button>
                </div>
              </div>
              {currentProduct.productType !== "Bag" && (
                <div className={styles.productDetails_container_content}>
                  <h2 className={styles.productDetails_h2}>
                    Technical Details
                  </h2>
                  <ul className={styles.productDetails_list}>
                    <li className={styles.productDetails_list_listItem}>
                      <p className={styles.productDetails_list_listItem_p1}>
                        <span
                          className={
                            styles.productDetails_list_listItem_p1_heading
                          }
                        >
                          Material Type :{" "}
                        </span>
                        <span
                          className={
                            styles.productDetails_list_listItem_p1_description
                          }
                        >
                          {currentProduct.materialType?.toUpperCase() ||
                            "Acrylic"}
                        </span>
                      </p>
                    </li>
                    <li className={styles.productDetails_list_listItem}>
                      <p className={styles.productDetails_list_listItem_p1}>
                        <span
                          className={
                            styles.productDetails_list_listItem_p1_heading
                          }
                        >
                          Sound Type :{" "}
                        </span>
                        <span
                          className={
                            styles.productDetails_list_listItem_p1_description
                          }
                        >
                          {currentProduct.soundType?.toUpperCase() || "Base"}
                        </span>
                      </p>
                    </li>
                    <li className={styles.productDetails_list_listItem}>
                      <p className={styles.productDetails_list_listItem_p1}>
                        <span
                          className={
                            styles.productDetails_list_listItem_p1_heading
                          }
                        >
                          Scale Type :{" "}
                        </span>
                        <span
                          className={
                            styles.productDetails_list_listItem_p1_description
                          }
                        >
                          {currentProduct.scaleType || "A"}
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              )}

              <div className={styles.productDetails_container_content}>
                <h2 className={styles.productDetails_h2}>Description</h2>
                <p className={styles.productDetails_p}>
                  {currentProduct.description || "description"}
                </p>
              </div>
              <div
                className={`${styles.color_more} ${
                  moreColor ? styles.color_more_visual : ""
                }`}
              >
                <div className={styles.color_more_wrapper}>
                  {imageKeys.map((image, index) => {
                    return (
                      <div
                        className={styles.color_choose_container}
                        key={index}
                      >
                        <img
                          key={index}
                          src={images[image]}
                          alt={`T-${index + 1}`}
                          width={60}
                          onClick={() => {
                            setColor(index);
                          }}
                        />
                        <span>T-{index + 1}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.Demo__container}>
            <h2>Share via: </h2>
            <div className={styles.Demo__some_network}>
              <WhatsappShareButton
                url={shareUrl}
                title={title}
                separator=":: "
                className={styles.Demo__some_network__share_button}
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </div>

            <div className={styles.Demo__some_network}>
              <FacebookShareButton
                url={shareUrl}
                className={styles.Demo__some_network__share_button}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </div>

            <div className={styles.Demo__some_network}>
              <TelegramShareButton
                url={shareUrl}
                title={title}
                separator=":: "
                className={styles.Demo__some_network__share_button}
              >
                <TelegramIcon size={32} round />
              </TelegramShareButton>
            </div>
          </div>
        </div>
      )}

      <SectionCarousel sectionName="Similar Product" array="NewArrival" />
    </div>
  );
};

export default ProductDetails;
