import { createSlice } from "@reduxjs/toolkit";

const randomProductInitialState = {
  BestSelling: [],
  NewArrival: [],
  FryBamboo: [],
  Bamboo: [],
  Acrylic: [],
  Vertical: [],
  PVC: [],
  randomProductLoading: false,
  isRandomProductFetch: false,
};

const RandomProductSlice = createSlice({
  name: "randomProduct",
  initialState: randomProductInitialState,
  reducers: {
    setBestSelling(state, action) {
      if (!action.payload.product || action.payload.product.length === 0) {
        return;
      }
      state.BestSelling = action.payload.product;
    },
    setNewArrival(state, action) {
      if (!action.payload.product || action.payload.product.length === 0) {
        return;
      }
      state.NewArrival = action.payload.product;
    },
    setFryBamboo(state, action) {
      if (
        !action.payload.productData ||
        action.payload.productData.length === 0
      ) {
        return;
      }
      state.FryBamboo = action.payload.productData;
    },
    setBamboo(state, action) {
      if (
        !action.payload.productData ||
        action.payload.productData.length === 0
      ) {
        return;
      }
      state.Bamboo = action.payload.productData;
    },
    setAcrylic(state, action) {
      if (
        !action.payload.productData ||
        action.payload.productData.length === 0
      ) {
        return;
      }
      state.Acrylic = action.payload.productData;
    },
    setVertical(state, action) {
      if (
        !action.payload.productData ||
        action.payload.productData.length === 0
      ) {
        return;
      }
      state.Vertical = action.payload.productData;
    },
    setPVC(state, action) {
      if (
        !action.payload.productData ||
        action.payload.productData.length === 0
      ) {
        return;
      }
      state.PVC = action.payload.productData;
    },
    randomProductLoading(state, action) {
      state.randomProductLoading = action.payload;
    },
    randomProductFetched(state, action) {
      state.isRandomProductFetch = action.payload;
    },
  },
});

export const RandomProductAction = RandomProductSlice.actions;
export default RandomProductSlice.reducer;
