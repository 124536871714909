import { configureStore } from "@reduxjs/toolkit";
import Auth from "./auth-slice";
import backDrop from "./backdrop-slice";
import UserSlice from "./user-slice";
import ProductSlice from "./product-slice";
import AddressSlice from "./address-slice";
import CartSlice from "./cart-slice";
import FilterSlice from "./filter-slice";
import RandomProductSlice from "./randomProduct-slice";
import OrderSlice from "./order-slice";
import CheckoutSlice from "./checkout-slice";

const store = configureStore({
  reducer: {
    Auth,
    BackDrop: backDrop,
    UserSlice,
    ProductSlice,
    AddressSlice,
    CartSlice,
    FilterSlice,
    RandomProductSlice,
    OrderSlice,
    CheckoutSlice,
  },
});

export default store;
