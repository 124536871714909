import AliceCarousel from "react-alice-carousel";
import "./homeCarousel.css";
import styles from "./_homeCarousel.module.scss";
import img1 from "../../../Images/DeepakFulte_home_des.jpeg";

const mainCarouselData = [
  {
    image: img1,
    path: "flute_background",
  },
];

const HomeCarousel = () => {
  const items = mainCarouselData.map((item, index) => (
    <img
      key={index}
      className={styles.homeCarousel}
      role="presentation"
      src={item.image}
      alt={item.path}
    />
  ));
  return (
    <AliceCarousel
      items={items}
      disableButtonsControls
      controlsStrategy="responsive"
    />
  );
};

export default HomeCarousel;
