import { createSlice } from "@reduxjs/toolkit";

const initialProductState = {
  data: [],
  currentPage: 1,
  totalProducts: 0,
  itemsPerPage: 0,
  isProductFetch: false,
  productLoading: false,
  isError: false,
};

const ProductSlice = createSlice({
  name: "product",
  initialState: initialProductState,
  reducers: {
    replaceActivity(state, action) {
      if (!action.payload) {
        return;
      }
      const { productData, totalProducts, itemsPerPage } = action.payload;
      const newData = Array.isArray(productData) ? productData : [];
      state.data = newData;
      state.totalProducts = totalProducts;
      state.itemsPerPage = itemsPerPage;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload.currentPage || 1;
    },
    dataFetched(state, action) {
      state.isProductFetch = action.payload;
    },
    productLoading(state, action) {
      state.productLoading = action.payload;
    },
    appendData(state, action) {
      if (!action.payload) {
        return;
      }
      const { productData, totalProducts, itemsPerPage } = action.payload;

      const newData = Array.isArray(productData) ? productData : [];

      return {
        ...state,
        data: [...state.data, ...newData],
        totalProducts: totalProducts,
        itemsPerPage: itemsPerPage || state.itemsPerPage,
      };
    },
    clearData(state) {
      state.data = [];
      state.totalProducts = 0;
      state.itemsPerPage = 0;
      state.currentPage = 1;
    },
  },
});

export const ProductAction = ProductSlice.actions;
export default ProductSlice.reducer;
