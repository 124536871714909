import { ShoppingCart, ShoppingBag, User } from "lucide-react";
import styles from "./_dropDown.module.scss";
import { AuthActions } from "../../../../Store/auth-slice";
import { UserActions } from "../../../../Store/user-slice";
import { useDispatch, useSelector } from "react-redux";
import { backDropActions } from "../../../../Store/backdrop-slice";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Button from "../../button/Button";
import {
  toastifyError,
  toastifySuccess,
} from "../../../../Helpers/notificationToastify";
import { useState } from "react";

const DropDown = (props) => {
  const classes = `${props.className} ${styles.list}`;
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.Auth);
  const [isHovered, setIsHovered] = useState();

  const logoutHandler = () => {
    dispatch(AuthActions.loading(true));
    fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/auth/logout`, {
      method: "GET",
      credentials: "include", // Include credentials to send the server's session cookie
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (response.ok) {
          dispatch(AuthActions.logout());
          dispatch(UserActions.userLogOut());
          dispatch(backDropActions.mobileNavHandler(false));
          toastifySuccess("You're securely logged out. See you soon!", "👋");
          dispatch(AuthActions.loading(false));
        } else {
          const errData = await response.json();
          throw errData;
        }
      })
      .catch((error) => {
        dispatch(AuthActions.loading(false));
        toastifyError(error.message, "⛔");
      });
  };
  return (
    <ul className={classes}>
      <li className={styles.items}>
        <Link
          className={styles.link}
          to="/account"
          onClick={() => {
            dispatch(backDropActions.mobileNavHandler(false));
          }}
        >
          <User className={styles.icon} />
          My Account
        </Link>
      </li>
      <li className={styles.items}>
        <Link
          className={styles.link}
          to="/cart"
          onClick={() => {
            dispatch(backDropActions.mobileNavHandler(false));
          }}
        >
          <ShoppingCart className={styles.icon} />
          My Cart
        </Link>
      </li>
      <li className={styles.items}>
        <Link
          className={styles.link}
          to="/order"
          onClick={() => {
            dispatch(backDropActions.mobileNavHandler(false));
          }}
        >
          <ShoppingBag className={styles.icon} />
          My Order
        </Link>
      </li>
      <li className={`${styles.items} ${styles.items_button}`}>
        {!authState.authLoading ? (
          <Button
            className={`${styles.button} ${styles.button_login}`}
            onClick={() => {
              logoutHandler();
              setIsHovered(true);
            }}
          >
            Logout
          </Button>
        ) : (
          <Button
            className={`${styles.button} ${styles.button_login}`}
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => setIsHovered(false)}
          >
            <l-dot-wave
              size="40"
              speed="2.5"
              color={isHovered ? "#e14d2a" : "white"}
            ></l-dot-wave>
          </Button>
        )}
      </li>
    </ul>
  );
};

export default DropDown;
