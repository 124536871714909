import { RandomProductAction } from "./randomProduct-slice";

export const randomProductTrunk = (
  identify,
  filter = { materialType: "fry Bamboo" }
) => {
  return async (dispatch) => {
    const getRandomProduct = async () => {
      dispatch(RandomProductAction.randomProductLoading(true));
      dispatch(RandomProductAction.randomProductFetched(false));

      if (
        identify === "Best Selling" ||
        identify === "New Arrival" ||
        identify === "Similar Product"
      ) {
        return fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/getInfo/product`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
          },
        })
          .then((response) => {
            if (!response.ok) {
              const resData = response.json();
              throw new Error(resData);
            }
            return response.json();
          })
          .catch((err) => {
            console.log(err);
            dispatch(RandomProductAction.randomProductLoading(false));
          });
      } else {
        return fetch(
          `${process.env.REACT_APP_SERVER_DOMAIN}/getInfo/product?page=1`,
          {
            method: "POST",
            body: JSON.stringify(filter),
            headers: {
              "content-type": "application/json",
              Accept: "application/json",
            },
          }
        )
          .then(async (response) => {
            if (!response.ok) {
              const resData = await response.json();
              throw new Error(resData);
            }
            return response.json();
          })
          .catch((err) => {
            console.log(err);
            dispatch(RandomProductAction.randomProductLoading(false));
          });
      }
    };

    try {
      const randomProduct = await getRandomProduct();
      if (identify === "Best Selling") {
        dispatch(RandomProductAction.setBestSelling(randomProduct));
      } else if (identify === "New Arrival" || identify === "Similar Product") {
        dispatch(RandomProductAction.setNewArrival(randomProduct));
      } else if (identify === "fry Bamboo") {
        dispatch(RandomProductAction.setFryBamboo(randomProduct));
      } else if (identify === "Bamboo") {
        dispatch(RandomProductAction.setBamboo(randomProduct));
      } else if (identify === "acrylic") {
        dispatch(RandomProductAction.setAcrylic(randomProduct));
      } else if (identify === "Vertical") {
        dispatch(RandomProductAction.setVertical(randomProduct));
      } else if (identify === "PVC") {
        dispatch(RandomProductAction.setPVC(randomProduct));
      }
      dispatch(RandomProductAction.randomProductLoading(false));
      dispatch(RandomProductAction.randomProductFetched(true));
    } catch (error) {
      dispatch(RandomProductAction.randomProductLoading(false));
    }
  };
};
