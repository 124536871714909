import { createSlice } from "@reduxjs/toolkit";

const initialUser = {
  name: "MAHADEV",
  image: "",
  isUser: false,
};

const UserSlice = createSlice({
  name: "user",
  initialState: initialUser,
  reducers: {
    replaceUser(state, action) {
      state.name = action.payload.name;
    },
    userLogOut(state) {
      state.name = "";
      state.image = "";
    },
    isUser(state, action) {
      state.isUser = action.payload;
    },
  },
});

export const UserActions = UserSlice.actions;
export default UserSlice.reducer;
