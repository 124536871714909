import React from "react";
import styles from "./_mobileNav.module.scss";
import Nav from "../header/Nav";
import { useDispatch, useSelector } from "react-redux";
import { backDropActions } from "../../../Store/backdrop-slice";
import { XCircle } from "lucide-react";

const MobileNav = () => {
  const backDrop = useSelector((state) => state.BackDrop);
  const dispatch = useDispatch();
  const MobileNavClasses = `${styles.mobileNav} ${
    backDrop.mobileNav ? styles.open : ""
  }`;
  return (
    <nav className={MobileNavClasses}>
      <div
        onClick={() => {
          dispatch(backDropActions.mobileNavHandler(false));
        }}
        className={styles.logo}
      >
        <XCircle style={{ height: "100%", width: "2.5rem", color: "white" }} />
      </div>
      <Nav></Nav>
    </nav>
  );
};

export default MobileNav;
