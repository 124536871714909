import { useEffect } from "react";
import OrderCard from "./OrderCard/OrderCard";
import styles from "./_order.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { OrderAction } from "../../../Store/order-slice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { scrollToTop } from "../../../Helpers/goToTop";
import Button from "../../Utils/button/Button";
import Card from "../../Utils/Card/Card";
import { ChevronRight, ChevronLeft } from "lucide-react";
import { trio } from "ldrs";

trio.register();

const Order = () => {
  const orderState = useSelector((state) => state.OrderSlice);
  const authState = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const nextPage = () => {
    if (orderState.isNextPage) {
      dispatch(
        OrderAction.setCurrentPage({ currentPage: orderState.currentPage + 1 })
      );
    }
  };

  const previousPage = () => {
    if (orderState.currentPage > 1) {
      dispatch(
        OrderAction.setCurrentPage({ currentPage: orderState.currentPage - 1 })
      );
    }
  };

  useEffect(() => {
    scrollToTop();
  });

  useEffect(() => {
    if (authState.isLoggedIn && authState.token) {
      try {
        async function fetchOrder() {
          dispatch(OrderAction.isOrderFetched(true));
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_DOMAIN}/order/getOrder?page=${orderState.currentPage}`,
            {
              method: "POST",
              headers: {
                "content-type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + authState.token,
              },
            }
          );
          if (!response.ok) {
            const errData = await response.json();
            throw new Error(errData);
          }
          const resData = await response.json();
          dispatch(OrderAction.setOrders(resData));
          dispatch(OrderAction.isOrderFetched(false));
          dispatch(OrderAction.orderLoading(false));
          scrollToTop();
        }
        fetchOrder();
      } catch (error) {
        console.log(error);
      }
    } else {
      history.push("/login");
    }
  }, [
    dispatch,
    orderState.currentPage,
    authState.token,
    authState.isLoggedIn,
    history,
  ]);
  return (
    <div className={styles.order}>
      <h1 className={styles.h1}>All Orders</h1>
      <div className={styles.order_card}>
        {!orderState.isOrderFetched ? (
          orderState.orders.map((product) => (
            <OrderCard key={product._id} product={product} />
          ))
        ) : (
          <l-trio
            size="80"
            speed="1.5"
            color="#e14d2a"
            style={{ margin: "0 auto", display: "block" }}
          ></l-trio>
        )}
        {!orderState.isOrderFetched && orderState.totalOrders === 0 && (
          <Card
            className={styles.noOrder}
            onClick={() => {
              history.push("/cart");
            }}
          >
            <h2>No Order Found</h2>
            <Button className={styles.btn}>Go To Cart</Button>
          </Card>
        )}
      </div>
      {!orderState.isOrderFetched && orderState.totalOrders !== 0 && (
        <div className={styles.paging}>
          <Button
            className={`${styles.btn} ${
              orderState.currentPage === 1 ? styles.inActive : ""
            }`}
            onClick={previousPage}
          >
            <ChevronLeft />
          </Button>
          <span>{orderState.currentPage}</span>
          <Button
            className={`${styles.btn} ${
              orderState.isNextPage ? "" : styles.inActive
            }`}
            onClick={nextPage}
          >
            <ChevronRight />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Order;
