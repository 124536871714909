import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Card from "../../../Utils/Card/Card";
import Button from "../../../Utils/button/Button";
import styles from "./_deliveryCard.module.scss";
import { MapPin, Phone } from "lucide-react";
import { useSelector } from "react-redux";

const DeliveryCard = (props) => {
  const history = useHistory();
  const authState = useSelector((state) => state.Auth);

  const changeDeliveryAddressHandler = () => {
    if (authState.isLoggedIn && authState.token) {
      history.push("/address");
    } else {
      history.push("/login");
    }
  };

  const editDeliveryAddressHandler = (addressId) => {
    if (authState.isLoggedIn && authState.token) {
      history.push(`/editAddress/:${addressId}`);
    } else {
      history.push("/login");
    }
  };

  return (
    <Card className={styles.addressCard}>
      <h3 className={styles.h3}>
        {`${props.address?.firstName} ${props.address?.lastName}` || "Error"}
      </h3>
      <div className={styles.addressCard_address}>
        <MapPin className={styles.icon}></MapPin>
        <p className={styles.p}>
          {`${props.address?.address} ${props.address?.city} ${props.address?.state}-${props.address?.zipCode}` ||
            "Not found pls go to home page"}
        </p>
      </div>
      <div className={styles.addressCard_address}>
        <Phone className={styles.icon} />
        <p className={styles.p}>{props.address?.phoneNumber || "Not Found"}</p>
      </div>
      <div className={styles.btn_container}>
        <Button
          className={styles.btn}
          onClick={() => {
            changeDeliveryAddressHandler();
          }}
        >
          Change
        </Button>
        <Button
          className={styles.btn}
          onClick={() => {
            editDeliveryAddressHandler(props.addressId);
          }}
        >
          Edit
        </Button>
      </div>
    </Card>
  );
};

export default DeliveryCard;
