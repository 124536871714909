import React from "react";
import styles from "./_card.module.scss";

const Card = (props) => {
  const classes = `${styles.card_center} ${styles.card} ${props.className}`;

  const clickHandler = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <div className={classes} onClick={clickHandler}>
      {props.children}
    </div>
  );
};

export default Card;
