import styles from "./_button.module.scss";

const Button = (props) => {
  const classes = `${styles.btn} ${props.className}`;
  const click = () => {
    if (props.onClick !== undefined) {
      props.onClick();
    }
  };

  return (
    <button
      onClick={click}
      onMouseEnter={() => {
        if (props.onMouseEnter !== undefined) {
          props.onMouseEnter();
        }
      }}
      onMouseLeave={() => {
        if (props.onMouseLeave !== undefined) {
          props.onMouseLeave();
        }
      }}
      className={classes}
    >
      {props.children}
    </button>
  );
};
export default Button;
