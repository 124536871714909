import React, { useRef, useEffect } from "react";
import Card from "../../Utils/Card/Card";
import styles from "./_login.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AuthActions } from "../../../Store/auth-slice";
import { UserActions } from "../../../Store/user-slice";
import { useState } from "react";
import Error from "../../Modal/Error/Error";
import { backDropActions } from "../../../Store/backdrop-slice";
import googleIcon from "../../../Images/google.svg";
import fluteIcon from "../../../Images/flute.png";
import { Mail } from "lucide-react";
import { toastifySuccess } from "../../../Helpers/notificationToastify";

const calcRemTime = (expTime) => {
  const currTime = new Date().getTime();
  const expirationTime = new Date(expTime).getTime();
  return expirationTime - currTime;
};

const Login = () => {
  const inputEmail = useRef();
  const inputPassword = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.Auth);
  const [error, setError] = useState({ isError: false, errorMessage: "" });
  const [isFirst, setIsFirst] = useState(false);
  const backDrop = useSelector((state) => state.BackDrop);

  useEffect(() => {
    dispatch(backDropActions.backDropHandler(true));
  }, [dispatch]);

  useEffect(() => {
    if (!isFirst) {
      setIsFirst(true);
    }
    if (isFirst && !backDrop.backDrop) {
      history.push("/");
    }
  }, [isFirst, history, backDrop]);

  const googleAuthHandler = async (event) => {
    window.open(`${process.env.REACT_APP_SERVER_DOMAIN}/auth/google`, "_self");
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const fetchUser = async () => {
      dispatch(AuthActions.loading(true));
      const inputUserEmail = inputEmail.current.value;
      const inputUserPassword = inputPassword.current.value;
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/auth/login`,
        {
          method: "POST",
          body: JSON.stringify({
            email: inputUserEmail,
            password: inputUserPassword,
          }),
          headers: {
            "content-type": "application/json",
          },
        }
      );

      dispatch(AuthActions.loading(false));

      if (!response.ok) {
        const resData = await response.json();
        throw resData;
      }
      const resData = await response.json();
      const expirationTime = new Date().getTime() + +60 * 60 * 1000;
      localStorage.setItem("expirationTime", expirationTime);
      const remainingTime = calcRemTime(expirationTime);
      dispatch(
        AuthActions.login({
          token: resData.token,
          time: expirationTime,
          timer: setTimeout(() => {
            dispatch(AuthActions.logout());
            dispatch(UserActions.userLogOut());
          }, remainingTime),
        })
      );
      dispatch(
        UserActions.replaceUser({
          name: resData.name,
        })
      );
      dispatch(backDropActions.backDropHandler(false));
      toastifySuccess("Welcome back! You're securely logged in.", "🔑");
      history.push("/");
    };
    try {
      await fetchUser();
    } catch (error) {
      setError({ isError: true, errorMessage: error.message });
    }
  };

  return (
    <Card className={styles.card_login}>
      <div className={styles.profile_pic}>
        <div>
          <h1>Welcome Back</h1>
          <div className={styles.iconContainer_flute}>
            <img src={fluteIcon} alt="fluteIcon" />
          </div>
          <p className={styles.par}>
            Sign in to place order for your favorite flutes.
          </p>
        </div>
        <div>
          <div
            className={`${styles.iconContainer} ${styles.iconContainer_email}`}
            onClick={googleAuthHandler}
          >
            <div className={styles.iconContainer_google}>
              <Mail />
            </div>
            <p>Sign in with Email</p>
          </div>
          <div className={styles.iconContainer} onClick={googleAuthHandler}>
            <div className={styles.iconContainer_google}>
              <img src={googleIcon} alt="google" />
            </div>
            <p>Sign in with Google</p>
          </div>
        </div>
        <div className={styles.account}>
          <h2>Create new account</h2>
          <button
            className={`${styles.btn} ${styles.account_btn}`}
            onClick={() => {
              history.push("/signup");
            }}
          >
            Sign up
          </button>
        </div>
      </div>
      <div className={styles.for_login}>
        {error.isError && <Error message={error.errorMessage}></Error>}

        <div className={styles.heading}>
          <h1>Login Form</h1>
        </div>
        <form className={styles.login_form}>
          <div className={styles.form_control}>
            <label htmlFor="email">E-mail</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email Address"
              ref={inputEmail}
            />
          </div>
          <div className={styles.form_control}>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              ref={inputPassword}
            />
          </div>
          {!auth.authLoading && (
            <button
              onClick={submitHandler}
              className={styles.btn}
              type="submit"
            >
              Login
            </button>
          )}
          {auth.authLoading && (
            <button
              onClick={(e) => {
                e.preventDefault();
              }}
              className={styles.btn}
            >
              Loading...
            </button>
          )}
        </form>
      </div>
    </Card>
  );
};

export default Login;
