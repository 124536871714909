import { createSlice } from "@reduxjs/toolkit";

const checkoutInitialState = {
  checkoutLoading: false,
};

const CheckoutSlice = createSlice({
  name: "checkout",
  initialState: checkoutInitialState,
  reducers: {
    checkoutLoading(state, action) {
      state.checkoutLoading = action.payload;
    },
  },
});

export const CheckoutAction = CheckoutSlice.actions;
export default CheckoutSlice.reducer;
