import styles from "./_toastify.module.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { toastifySuccess } from "../../../Helpers/notificationToastify";

const Toastify = () => {
  // const notify = () => toastifySuccess("hello notification");
  return (
    <div className={styles.toastify}>
      {/* <button onClick={notify}>Notify !</button> */}
      <ToastContainer
        className={styles.ToastContainer}
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Toastify;
