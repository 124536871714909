import Card from "../../../Utils/Card/Card";
import styles from "./_cartProduct.module.scss";
import image from "../../../../Images/flute.png";
import { useState } from "react";
import { Plus, Minus } from "lucide-react";
import Button from "../../../Utils/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { cartTrunk } from "../../../../Store/cart-action";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { calculateDiscountPercentage } from "../../../../Helpers/discountPercentage";
import images from "../../../../Helpers/importColorImages";
import { Brush } from "lucide-react";
import { bouncy } from "ldrs";
import { toastifyWarning } from "../../../../Helpers/notificationToastify";

bouncy.register();

const CartProduct = (props) => {
  const [quantity, setQuantity] = useState(props.addedProduct.quantity || 1);
  const [hand, setHand] = useState(props.addedProduct.hand || 0);
  const [finger, setFinger] = useState(props.addedProduct.finger || 0);
  const [color, setColor] = useState(props.addedProduct.color || 0);
  const [bag, setBag] = useState(props.addedProduct.bag || 0);
  const [moreColor, setMoreColor] = useState(false);
  const imageKeys = Object.keys(images);
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.Auth);
  const cartState = useSelector((state) => state.CartSlice);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const history = useHistory();

  const UpdateCartHandler = () => {
    if (!authState.isLoggedIn || !authState.token) {
      toastifyWarning(
        "SignUp or LogIn for exclusive flute offers and updates.",
        "🎵"
      );
      history.push("/login");
    } else {
      const token = authState.token;
      dispatch(
        cartTrunk(
          token,
          quantity,
          "update",
          props.productId,
          hand,
          finger,
          color,
          bag,
          imageKeys.length
        )
      );
    }
  };

  const discountPercentage = calculateDiscountPercentage(
    props.prod.price || 100,
    props.prod.discount || 0
  );

  const discountPrice = props.prod.price - props.prod.discount;

  const decreaseQuantity = () => {
    if (authState.isLoggedIn && authState.token && quantity >= 1) {
      const token = authState.token;
      dispatch(
        cartTrunk(token, 1, "reduce", props.productId, hand, finger, color, bag)
      );
      setQuantity(quantity - 1);
    } else {
      history.push("/login");
    }
  };
  const increaseQuantity = () => {
    if (authState.isLoggedIn && authState.token && quantity < 10) {
      const token = authState.token;
      dispatch(
        cartTrunk(
          token,
          1,
          "increase",
          props.productId,
          hand,
          finger,
          color,
          bag
        )
      );
      setQuantity(quantity + 1);
    }
  };

  const deleteCartHandler = () => {
    if (authState.isLoggedIn && authState.token) {
      const token = authState.token;
      dispatch(
        cartTrunk(token, 1, "delete", props.productId, hand, finger, color, bag)
      );
    } else {
      history.push("/login");
    }
  };

  return (
    <Card
      className={styles.cartProduct}
      onClick={() => {
        if (moreColor) {
          setMoreColor(false);
        }
      }}
    >
      <div className={styles.cartProduct_top}>
        <div className={styles.imgContainer}>
          <img src={props.prod?.images?.[0]?.image || image} alt="flute" />
        </div>
        <div className={`${styles.cartProduct_top_content} h-full`}>
          <h2 className={styles.h2}>
            {props.prod.heading || "Inter Server error: "}
          </h2>
          <div className={styles.cartProduct_top_content_price}>
            <span className={styles.cartProduct_top_content_price_new}>
              ₹{discountPrice || "error"}
            </span>
            <span className={styles.cartProduct_top_content_price_old}>
              ₹{props.prod?.price || "error"}
            </span>
            <div className={styles.cartProduct_top_content_price_discount}>
              <p>{discountPercentage || "err"}% OFF</p>
            </div>
          </div>
          {props.addedProduct.productType !== "Bag" && (
            <form
              style={{ width: "max-content" }}
              className={styles.back_feature}
            >
              <p>Hand: </p>
              <div className={styles.back_feature_container}>
                <input
                  type="radio"
                  id="leftRadio"
                  name="hand"
                  value={0}
                  onChange={() => {
                    setHand(0);
                  }}
                  checked={hand === 0}
                />

                <label htmlFor="leftRadio">Left flute</label>
              </div>
              <div className={styles.back_feature_container}>
                <input
                  type="radio"
                  id="rightRadio"
                  name="hand"
                  value={1}
                  onChange={() => {
                    setHand(1);
                  }}
                  checked={hand === 1}
                />

                <label htmlFor="rightRadio">Right flute</label>
              </div>
            </form>
          )}

          <form style={{ display: "none" }} className={styles.back_feature}>
            <p>Finger Hole: </p>
            <div className={styles.back_feature_container}>
              <input
                type="radio"
                id="customized"
                name="finger"
                value={0}
                checked={finger === 0}
                onChange={() => {
                  setFinger(0);
                }}
              />

              <label htmlFor="customized">Customized</label>
            </div>
            <div className={styles.back_feature_container}>
              <input
                type="radio"
                id="regular"
                name="finger"
                value={1}
                checked={finger === 1}
                onChange={() => {
                  setFinger(1);
                }}
              />

              <label htmlFor="regular">Regular</label>
            </div>
          </form>
          {props.addedProduct.productType !== "Bag" && (
            <form className={styles.back_feature}>
              <p>Bag: </p>
              <div className={styles.back_feature_container}>
                <input
                  type="radio"
                  id="without"
                  name="Bag"
                  value={0}
                  checked={bag === 0}
                  onChange={() => {
                    setBag(0);
                  }}
                />

                <label htmlFor="without">Without</label>
              </div>
              <div className={styles.back_feature_container}>
                <input
                  type="radio"
                  id="included"
                  name="Bag"
                  value={1}
                  checked={bag === 1}
                  onChange={() => {
                    setBag(1);
                  }}
                />

                <label htmlFor="included">Include</label>
              </div>
            </form>
          )}

          <div style={{ display: "none" }} className={styles.back_feature}>
            <p>Color:</p>
            <div className={styles.color_choose}>
              <div className={styles.wrapper}>
                <div className={styles.color_choose_container}>
                  <img
                    value={color}
                    src={images[imageKeys[color]]}
                    alt={`T-${color + 1}`}
                    width={55}
                    className={styles.selected}
                  ></img>
                  <span>T-{color + 1}</span>
                </div>
                <div className={styles.color_choose_container}>
                  <img
                    src={
                      color === imageKeys.length - 1
                        ? images[imageKeys[color - 1]]
                        : images[imageKeys[color + 1]]
                    }
                    alt="T-2"
                    width={55}
                    onClick={() => {
                      setColor(
                        color === imageKeys.length - 1 ? color + 1 : color + 1
                      );
                    }}
                  ></img>
                  <span>
                    T-
                    {color === imageKeys.length - 1 ? color : color + 2}
                  </span>
                </div>
              </div>
            </div>

            <Button
              className={`${styles.btn} ${styles.color_btn}`}
              onClick={() => {
                setMoreColor(true);
              }}
            >
              <Brush style={{ width: "1rem", marginRight: "5px" }} /> More
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.cartProduct_bottom}>
        <div className={styles.cartProduct_bottom_container}>
          {cartState.cartLoading ? (
            <l-bouncy size="65" speed="1.75" color="#e14d2a"></l-bouncy>
          ) : (
            <>
              <Minus onClick={decreaseQuantity} className={styles.icon} />
              <span className={styles.quantity}>{quantity}</span>
              <Plus onClick={increaseQuantity} className={styles.icon} />
            </>
          )}
        </div>
        <div className={styles.btn_container}>
          {!cartState.cartLoading ? (
            <Button
              className={styles.btn}
              onClick={() => {
                UpdateCartHandler();
                setIsHovered(true);
              }}
            >
              Update
            </Button>
          ) : (
            <Button
              className={styles.btn}
              onMouseEnter={() => {
                setIsHovered(true);
              }}
              onMouseLeave={() => setIsHovered(false)}
            >
              <l-dot-wave
                size="30"
                speed="2.5"
                color={isHovered ? "#e14d2a" : "white"}
              ></l-dot-wave>
            </Button>
          )}
          {!cartState.cartLoading ? (
            <Button
              className={styles.btn}
              onClick={() => {
                deleteCartHandler();
                setIsHovered2(true);
              }}
            >
              Remove
            </Button>
          ) : (
            <Button
              className={styles.btn}
              onMouseEnter={() => {
                setIsHovered2(true);
              }}
              onMouseLeave={() => setIsHovered2(false)}
            >
              <l-dot-wave
                size="30"
                speed="2.5"
                color={isHovered2 ? "#e14d2a" : "white"}
              ></l-dot-wave>
            </Button>
          )}
        </div>
      </div>
      <div
        className={`${styles.color_more} ${
          moreColor ? styles.color_more_visual : ""
        }`}
      >
        <div className={styles.color_more_wrapper}>
          {imageKeys.map((image, index) => {
            return (
              <div className={styles.color_choose_container} key={index}>
                <img
                  key={index}
                  src={images[image]}
                  alt={`T-${index + 1}`}
                  width={60}
                  onClick={() => {
                    setColor(index);
                  }}
                />
                <span>T-{index + 1}</span>
              </div>
            );
          })}
        </div>
      </div>
    </Card>
  );
};

export default CartProduct;
