import styles from "./_product.module.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import FilterForm from "./FilterForm/FilterForm";
import ProductCard from "./ProductCard/ProductCard";
import { dotWave, helix } from "ldrs";
import { useEffect, useState } from "react";
import { toastifyWarning } from "../../../Helpers/notificationToastify";
import Timer from "../../Utils/CountDownTimer/Timer";
dotWave.register();
helix.register();

const Product = () => {
  const filterState = useSelector((state) => state.FilterSlice);
  const productState = useSelector((state) => state.ProductSlice);
  const history = useHistory();
  const [salesDetails, setSalesDetails] = useState(null);

  const productDetailNavigator = (id) => {
    history.push("/product/" + id);
  };

  useEffect(() => {
    const getSaleDetails = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/sales/saleDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resData = await response.json();

      if (!response.ok) {
        throw new Error(resData.message);
      }

      if (resData.HasActive) {
        console.log("as", resData);
        setSalesDetails(resData.sale);
      } else {
        toastifyWarning("Sorry, Sales Closed.");
        history.push("/");
      }
    };

    if (
      filterState.filter?.productType === "flute" &&
      filterState.filter?.fluteType === "Sales"
    ) {
      getSaleDetails();
    }
  }, [filterState.filter?.productType, filterState.filter?.fluteType, history]);

  return (
    <div className={`${styles.productFilter} relative`}>
      {filterState.filter?.productType === "Bag" ? (
        <h2 className={styles.productFilter_h2}>
          {filterState.filter.productType?.toUpperCase() || "ALL PRODUCTS"}
        </h2>
      ) : (
        <h2 className={styles.productFilter_h2}>
          {filterState.filter.materialType?.toUpperCase() ||
            filterState.filter.fluteType?.toUpperCase() ||
            "ALL PRODUCTS"}
        </h2>
      )}
      {salesDetails && filterState.filter?.fluteType === "Sales" && (
        <Timer
          type="right"
          TimeStamp={salesDetails.endingTime}
          saleHeading={salesDetails.heading}
        />
      )}
      <div className={styles.productFilter_content}>
        <FilterForm />
        <div className={styles.productFilter_content_item}>
          {(productState.isProductFetch || productState.data?.length !== 0) &&
            productState.data?.map((product, index) => (
              <ProductCard
                product={product}
                key={product._id}
                id={product._id}
                onClick={() => productDetailNavigator(product._id)}
              />
            ))}
          {productState.productLoading && productState.data.length === 0 && (
            <l-helix
              size="60"
              speed="2.5"
              color="#e14d2a"
              style={{ marginTop: "3.5rem", marginBottom: "3.5rem" }}
            ></l-helix>
          )}
          {!productState.productLoading && productState.totalProducts === 0 && (
            <h1 className={styles.noProduct}>NO PRODUCT FOUND</h1>
          )}
        </div>
      </div>
      {productState.isProductFetch && (
        <l-dot-wave
          size="60"
          speed="1.3"
          color="#e14d2a"
          style={{ alignSelf: "center", marginBottom: "10px" }}
        ></l-dot-wave>
      )}
    </div>
  );
};

export default Product;
