import React from 'react';

const ShippingPolicy = () => {
  return (
    <div style={{ padding: '20px', fontSize: '1em', lineHeight: '1.6' }}>
      <h1>Shipping Policy</h1>
      <p>
        We provide shipping and delivery of our Products all over India. We aim to provide the best customer experience for you by tying-up and partnering with leading logistics service providers to handle your order in the best possible way and to ensure that you have a hassle-free experience in receiving the Product that you have ordered from the Platform. We make all commercially reasonable endeavours to ensure that the Products are delivered to you within 7 days.
      </p>
      <p>
        You agree and understand that though we endeavour to ship and deliver our Products all across India, we may, in our sole discretion determine a select list of areas which are unserviceable for delivery of Products. We, or our Logistic Partners, do not provide shipping and delivery services in such unserviceable areas and may not process your orders on the Platform in such cases. In the event, an area has been deemed unserviceable by us, we shall notify such user at the time of placing an order for purchase of Products on the Platform. You may also verify whether an area is unserviceable for deliveries by us by entering the relevant area pin-code on the Platform.
      </p>
      <h2>Customer Support</h2>
      <p>
        You may direct any queries or concerns relating to the shipping and delivery of Products as per this Policy to our customer support team who can be contacted at the below mentioned details:
      </p>
      <p>
        Contact Details:
      </p>
      <p>
        E-mail id - <a href="mailto:deepaksoniflute@gmail.com">deepaksoniflute@gmail.com</a>
      </p>
    </div>
  );
};

export default ShippingPolicy;