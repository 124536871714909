import { Fragment, useEffect, useState } from "react";
import styles from "./_app.module.scss";
import BackDrop from "./Components/Modal/BackDrop";
import reactRouterDom from "react-dom";
import Header from "./Components/Utils/header/Header";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import { Route } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Signup from "./Components/Pages/SignUp/Signup";
import Login from "./Components/Pages/Login/Login";
import { AuthActions } from "./Store/auth-slice";
import { UserActions } from "./Store/user-slice";
import HomePage from "./Components/Pages/HomePage/HomePage";
import ProductDetails from "./Components/Pages/ProductDetails/ProductDetails";
import Footer from "./Components/FeatureComponents/Footer/Footer";
import Address from "./Components/Pages/Address/Address";
import Checkout from "./Components/Pages/Checkout/Checkout";
import Cart from "./Components/Pages/Cart/Cart";
import Order from "./Components/Pages/Order/Order";
import Product from "./Components/Pages/Product/Product";
import { cartTrunk } from "./Store/cart-action";
import MobileNav from "./Components/Utils/Mobile/MobileNav";
import Toastify from "./Components/Utils/Toastify/Toastify";
import { CartAction } from "./Store/cart-slice";
import { toastifyError, toastifySuccess } from "./Helpers/notificationToastify";
import { OrderAction } from "./Store/order-slice";
import { fetchUser } from "./Store/user-action";
import Timer from "./Components/Utils/CountDownTimer/Timer";
import RefundPolicy from "./Components/FeatureComponents/Footer/RefundPolicy";
import ShippingPolicy from "./Components/FeatureComponents/Footer/ShippingPolicy";
import PrivacyPolicy from "./Components/FeatureComponents/Footer/PrivacyPolicy";
import TermsOfUse from "./Components/FeatureComponents/Footer/TermsAndConditions";

const calcRemTime = (expTime) => {
  const currTime = new Date().getTime();
  const expirationTime = new Date(+expTime).getTime();
  return expirationTime - currTime;
};

const getStoredToken = () => {
  const storedToken = localStorage.getItem("token") || null;
  const storedExpirationTime = localStorage.getItem("expirationTime") || 0;
  const remainingTime = calcRemTime(storedExpirationTime);
  if (remainingTime <= 3600) {
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");

    return { token: null };
  }

  return {
    token: storedToken,
    duration: remainingTime,
  };
};

function App() {
  const authState = useSelector((state) => state.Auth);
  const [salesDetails, setSalesDetails] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const tokenData = getStoredToken();
    if (tokenData.token) {
      dispatch(
        AuthActions.login({
          token: tokenData.token,
          time: tokenData.duration,
          timer: setTimeout(() => {
            dispatch(AuthActions.logout());
          }, tokenData.duration),
        })
      );
      dispatch(fetchUser());
    } else {
      dispatch(AuthActions.logout());
    }
  }, [dispatch]);

  useEffect(() => {
    const tokenData = getStoredToken();
    if (authState.isLoggedIn && authState.token && tokenData.token !== null) {
      const token = authState.token;
      dispatch(cartTrunk(token, 0, "getCart"));
      dispatch(cartTrunk(token, 1, "cartView"));
    }
  }, [dispatch, authState.token, authState.isLoggedIn]);

  useEffect(() => {
    if (!authState.isLoggedIn) {
      dispatch(CartAction.clearCartData());
      dispatch(OrderAction.clearOrder());
    }
  }, [authState.isLoggedIn, dispatch]);

  useEffect(() => {
    const getUser = () => {
      fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/auth/success`, {
        method: "GET",
        credentials: "include", // Send cookies
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          throw new Error("Authentication failed");
        })
        .then((resData) => {
          const expirationTime = new Date().getTime() + +60 * 60 * 1000;
          localStorage.setItem("expirationTime", expirationTime);
          const remainingTime = calcRemTime(expirationTime);
          dispatch(
            AuthActions.login({
              token: resData.token,
              time: expirationTime,
              timer: setTimeout(() => {
                dispatch(AuthActions.logout());
                dispatch(UserActions.userLogOut());
              }, remainingTime),
            })
          );
          dispatch(
            UserActions.replaceUser({
              name: resData.name,
            })
          );
          toastifySuccess("Welcome back! You're securely logged in.", "🔑");
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (!authState.isLoggedIn) {
      getUser();
    }
  }, [dispatch, authState.isLoggedIn]);

  useEffect(() => {
    const getSaleDetails = async () => {
      try {
        console.log("getSaleDetails function run");
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_DOMAIN}/sales/saleDetails`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const resData = await response.json();

        if (!response.ok) {
          throw new Error(resData.message);
        }
        if (resData.HasActive) {
          setSalesDetails(resData.sale);
        }
      } catch (err) {
        toastifyError(err.message);
      }
    };
    getSaleDetails();
    console.log("fetched sales details");
  }, []);

  return (
    <Fragment>
      {reactRouterDom.createPortal(
        <Toastify />,
        document.getElementById("notification")
      )}
      {reactRouterDom.createPortal(
        <BackDrop></BackDrop>,
        document.getElementById("modal")
      )}
      {salesDetails &&
        reactRouterDom.createPortal(
          <Timer
            type="center"
            TimeStamp={salesDetails.endingTime}
            saleHeading={salesDetails.heading}
          />,
          document.getElementById("sales")
        )}
      <Header salesDetails={salesDetails}></Header>
      <MobileNav />
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="/product/:productId">
          <main className={styles.main}>
            <ProductDetails />
          </main>
        </Route>
        <Route path="/products">
          <main className={styles.main}>
            <Product />
          </main>
        </Route>
        {!authState.isLoggedIn && (
          <Route path="/login">
            <main className={styles.main}>
              <Login></Login>
            </main>
          </Route>
        )}
        {!authState.isLoggedIn && (
          <Route path="/signup">
            <main className={styles.main}>
              <Signup></Signup>
            </main>
          </Route>
        )}
        {authState.isLoggedIn && (
          <Route path="/editAddress/:addressId">
            <main className={styles.main}>
              <Address />
            </main>
          </Route>
        )}
        {authState.isLoggedIn && (
          <Route path="/Address">
            <main className={styles.main}>
              <Address />
            </main>
          </Route>
        )}
        {authState.isLoggedIn && (
          <Route path="/checkout/:addressId">
            <main className={styles.main}>
              <Checkout />
            </main>
          </Route>
        )}
        {authState.isLoggedIn && (
          <Route path="/cart">
            <main className={styles.main}>
              <Cart />
            </main>
          </Route>
        )}
        {authState.isLoggedIn && (
          <Route path="/order">
            <main className={styles.main}>
              <Order />
            </main>
          </Route>
        )}
        <Route path="/RefundPolicy">
          <main className={styles.main}>
            <RefundPolicy />
          </main>
        </Route>
        <Route path="/ShippingPolicy">
          <main className={styles.main}>
            <ShippingPolicy />
          </main>
        </Route>
        <Route path="/PrivacyPolicy">
          <main className={styles.main}>
            <PrivacyPolicy />
          </main>
        </Route>
        <Route path="/TermsAndCondition">
          <main className={styles.main}>
            <TermsOfUse />
          </main>
        </Route>
        <Route path="*">
          <HomePage />
        </Route>
      </Switch>
      <Footer />
    </Fragment>
  );
}

export default App;
