import { createSlice } from "@reduxjs/toolkit";

const orderInitialState = {
  orders: [],
  order_Pre_page: 0,
  totalOrders: 0,
  isOrderFetched: false,
  orderLoading: true,
  currentPage: 1,
  isNextPage: true,
};

const OrderSlice = createSlice({
  name: "order",
  initialState: orderInitialState,
  reducers: {
    setOrders(state, action) {
      const { orders, totalOrders, Order_Per_Page } = action.payload;
      const newData = Array.isArray(orders) ? orders : [];
      state.orders = newData;
      state.totalOrders = totalOrders;
      state.order_Pre_page = Order_Per_Page;
      if (state.currentPage * 2 >= totalOrders) {
        state.isNextPage = false;
      } else {
        state.isNextPage = true;
      }
    },
    orderLoading(state, action) {
      state.orderLoading = action.payload;
    },
    isOrderFetched(state, action) {
      state.isOrderFetched = action.payload;
    },
    setCurrentPage(state, action) {
      console.log(action.payload.currentPage);
      state.currentPage = action.payload.currentPage || 1;
    },
    clearOrder(state) {
      state.orders = [];
      state.isOrderFetched = false;
      state.order_Pre_page = 0;
      state.totalOrders = 0;
      state.isOrderFetched = false;
      state.currentPage = 1;
    },
  },
});

export const OrderAction = OrderSlice.actions;
export default OrderSlice.reducer;
