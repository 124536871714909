import { createSlice } from "@reduxjs/toolkit";

const addressInitialState = {
  addressData: [],
  addressLoading: false,
  isAddressFetch: false,
  deliveryAddress: null,
};

const AddressSlice = createSlice({
  name: "address",
  initialState: addressInitialState,
  reducers: {
    setAddress(state, action) {
      state.addressData = action.payload.allAddress || [];
    },
    addressLoading(state, action) {
      state.addressLoading = action.payload;
    },
    setDeliveryAddress(state, action) {
      state.deliveryAddress = action.payload.deliveryAddress || null;
    },
    isAddressFetch(state, action) {
      state.isAddressFetch = action.payload;
    },
  },
});

export const AddressAction = AddressSlice.actions;
export default AddressSlice.reducer;
