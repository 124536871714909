function importAllImages(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item] = r(item);
  });
  return images;
}

const images = importAllImages(
  require.context("../Images/color", false, /\.(png|jpe?g|svg|jpeg)$/)
);

export default images;
