import {
  toastifyError,
  toastifySuccess,
} from "../Helpers/notificationToastify";
import { CartAction } from "./cart-slice";

export const cartTrunk = (
  token,
  quantity = 1,
  action = "add",
  Id = "",
  hand = 0,
  finger = 0,
  color = 0,
  bag = 0,
  totalColor = 44
) => {
  return async (dispatch) => {
    const getCartData = async () => {
      dispatch(CartAction.cartLoading(true));

      if (action === "add") {
        dispatch(CartAction.cartViewFetched(false));
      }

      let url = "addToCart";
      if (action === "reduce") {
        url = "reduceToCart";
      } else if (action === "delete") {
        url = "deleteToCart";
      } else if (action === "getCart") {
        url = "getCart";
      } else if (action === "cartView") {
        url = "getCartView";
      } else if (action === "update") {
        url = "updateCart";
      }

      if (action === "cartView") {
        dispatch(CartAction.cartViewFetched(false));
        return fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/cart/` + url, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => {
            if (!response.ok) {
              const resData = response.json();
              throw new Error(resData);
            }
            return response.json();
          })
          .catch((err) => {
            console.log(err);
            CartAction.cartLoading(false);
          });
      } else if (action === "getCart") {
        dispatch(CartAction.cartFetched(false));
        return fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/cart/` + url, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => {
            if (!response.ok) {
              const resData = response.json();
              throw new Error(resData);
            }
            return response.json();
          })
          .catch((err) => {
            console.log(err);
            CartAction.cartLoading(false);
          });
      } else {
        const data = {
          productId: Id,
          quantity,
          hand,
          color,
          finger,
          bag,
        };

        return fetch(`${process.env.REACT_APP_SERVER_DOMAIN}/cart/` + url, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then(async (response) => {
            if (!response.ok) {
              const resData = await response.json();
              throw new Error(resData);
            }
            return response.json();
          })
          .catch((err) => {
            console.log(err);
            CartAction.cartLoading(false);
            throw err;
          });
      }
    };
    try {
      const cartData = await getCartData();
      if (cartData.hasCartView) {
        dispatch(CartAction.setCartView(cartData));
        dispatch(CartAction.cartViewFetched(true));
      } else {
        dispatch(CartAction.setCartData(cartData));
        dispatch(CartAction.setPayment(cartData));
        dispatch(CartAction.cartFetched(true));
        const totalItems = Object.values(cartData.cart).reduce(
          (acc, item) => item.quantity + acc,
          0
        );
        if (action === "delete") {
          toastifySuccess(
            `Item removed from your cart. Your updated total is ${totalItems} items.`,
            "❌"
          );
        } else if (action === "reduce") {
          if (cartData?.outOfStock) {
            toastifySuccess(
              `${cartData.message || "Sorry it's out of stock"}`,
              "🛒"
            );
          } else {
            toastifySuccess(
              `Quantity updated. You now have ${totalItems} items in your cart.`,
              "🔽"
            );
          }
        } else if (action === "increase") {
          if (cartData?.outOfStock) {
            toastifySuccess(
              `${cartData.message || "Sorry it's out of stock"}`,
              "🛒"
            );
          } else {
            toastifySuccess(
              `Quantity updated. You now have ${totalItems} items in your cart.`,
              "🔼"
            );
          }
        } else if (action === "update") {
          if (cartData?.outOfStock) {
            toastifySuccess(
              `${cartData.message || "Sorry it's out of stock"}`,
              "🛒"
            );
          } else {
            toastifySuccess(
              `Product updated (quantity: ${quantity}, color: ${
                color === totalColor.length - 1 ? color + 1 : color + 1
              }, hand: ${hand === 0 ? "left" : "right"},finger: ${
                finger === 0 ? "customized" : "regular"
              }). You now have ${totalItems} items in your cart.`,
              "🛒"
            );
          }
        }
      }
      dispatch(CartAction.cartLoading(false));
      if (action === "add") {
        if (cartData?.outOfStock) {
          toastifySuccess(
            `${cartData.message || "Sorry it's out of stock"}`,
            "🛒"
          );
        } else {
          toastifySuccess("Great choice! Item added to your cart.", "🛒");
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(CartAction.cartLoading(false));
      dispatch(CartAction.cartViewFetched(true));
      dispatch(CartAction.cartFetched(true));
      toastifyError("Pls try after some time!");
    }
  };
};
