import styles from "./_sectionCarousel.module.scss";
import sectionSeparator from "../../../Images/flute.png";
import SectionCarouselCard from "./SectionCarouselCard/SectionCarouselCard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { randomProductTrunk } from "../../../Store/randomProduct-action";
import { waveform } from "ldrs";
import { ProductAction } from "../../../Store/product-slice";
import { FilterAction } from "../../../Store/filter-slice";
import { Link } from "react-router-dom/cjs/react-router-dom";
waveform.register();

const SectionCarousel = (props) => {
  const dispatch = useDispatch();
  const randomProductState = useSelector((state) => state.RandomProductSlice);
  const { isRandomProductFetch, [props.array]: sectionProducts } =
    randomProductState;
  useEffect(() => {
    dispatch(
      randomProductTrunk(props.sectionName, { materialType: props.sectionName })
    );
  }, [dispatch, props.sectionName]);

  const setFilterTo = () => {
    dispatch(ProductAction.setCurrentPage({ currentPage: 1 }));
    dispatch(FilterAction.clearFilter());
    if (
      props.sectionName === "New Arrival" ||
      props.sectionName === "Best Selling" ||
      props.sectionName === "Similar Product"
    ) {
      dispatch(FilterAction.setFilter({ materialType: "" }));
    } else {
      dispatch(FilterAction.setFilter({ materialType: props.sectionName }));
    }
  };

  if (
    props.sectionName &&
    isRandomProductFetch &&
    sectionProducts?.length > 0
  ) {
    return (
      <div className={styles.sectionContainer}>
        <h2 className={styles.sectionContainer_h2}>
          {props.sectionName || "Best Selling"}
        </h2>
        <div className={styles.sectionContainer_imageContainer}>
          <img src={sectionSeparator} alt={props.sectionName || "image"} />
        </div>

        <div className={styles.sectionContainer_item}>
          {sectionProducts.map((item, i) => (
            <SectionCarouselCard data={item} key={i} productId={item._id} />
          ))}
        </div>
        <Link to="/products" onClick={setFilterTo} className={styles.btn}>
          See More
        </Link>
      </div>
    );
  } else if (props.sectionName && !isRandomProductFetch) {
    return (
      <div className={styles.sectionContainer}>
        <h2 className={styles.sectionContainer_h2}>
          {props.sectionName || "Best Selling"}
        </h2>
        <div className={styles.sectionContainer_imageContainer}>
          <img src={sectionSeparator} alt={props.sectionName || "image"} />
        </div>
        <div className={styles.sectionContainer_carousel}>
          <l-waveform
            size="60"
            stroke="3.5"
            speed="1"
            color="#e14d2a"
            style={{ display: "block", margin: "0 auto" }}
          ></l-waveform>
        </div>
      </div>
    );
  }
};

export default SectionCarousel;
