import AliceCarousel from "react-alice-carousel";
import "./imageCarousel.css";

const ImageCarousel = ({ images, onClick }) => {
  const items = images.map((image, index) => (
    <img onClick={onClick} key={index} src={image?.image} alt={image} />
  ));
  return (
    <AliceCarousel
      items={items}
      disableButtonsControls
      controlsStrategy="responsive"
    />
  );
};

export default ImageCarousel;
