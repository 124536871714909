import React from 'react'

function TermsOfUse() {
  return (
    <div>
      <h1>Terms of Use for Deepak Flutes</h1>

      <p>
        These terms of use ("Terms") govern your access to and use of the website (the "Website") and any services provided through the Website (collectively, the "Services"). By accessing or using the Services, you agree to be bound by these Terms. If you do not agree to these Terms, do not access or use the Services.
      </p>

      <h2>1. Use of Services</h2>
      <p>
        We provides an online platform for users to access flutes.</p>

      <h2>2. User Accounts</h2>
      <p>
        In order to access certain features of the Services, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to provide accurate and complete information when creating your account and to update your information as necessary to keep it accurate and current.
      </p>

      <h2>3. User Responsibilities</h2>
      <p>
        You agree not to:
      </p>
      <ul>
        <li>Use the Services in any manner that could disable, overburden, damage, or impair the Website or interfere with any other party's use of the Services.</li>
        <li>Use any robot, spider, or other automatic device, process, or means to access the Services for any purpose, including monitoring or copying any of the material on the Website.</li>
        <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Services, the server on which the Services are stored, or any server, computer, or database connected to the Services.</li>
        <li>Impersonate or attempt to impersonate us, an our employee, another user, or any other person or entity.</li>
        <li>Use the Services for any purpose that is unlawful or prohibited by these Terms.</li>
      </ul>

      <h2>4. Privacy Policy</h2>
      <p>
        Your use of the Services is governed by our Privacy Policy. By using the Services, you consent to our collection, use, and sharing of your information as described in the Privacy Policy.
      </p>

      <h2>5. Intellectual Property</h2>
      <p>
        The Website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio) are owned by us or its licensors and are protected by copyright, trademark, and other intellectual property laws.
      </p>

      <h2>6. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, WePediatrics disclaims all warranties, express or implied, regarding the Services and your use thereof. We shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to lost profits or data, arising from your use of the Services.
      </p>

      <h2>7. Modifications to Terms</h2>
      <p>
        We reserves the right to update or modify these Terms at any time without prior notice. Any changes to these Terms will be effective immediately upon posting. Your continued use of the Services following the posting of revised Terms means that you accept and agree to the changes.
      </p>

      <h2>8. Governing Law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the laws, without regard to its conflict of law principles.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions or concerns about these Terms, please contact us at deepaksoniflute@gmail.com.
      </p>
    </div>
  )
}

export default TermsOfUse