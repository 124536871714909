import { Link, useLocation } from "react-router-dom/cjs/react-router-dom";
import styles from "./_footer.module.scss";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductAction } from "../../../Store/product-slice";
import { toastifyError } from "../../../Helpers/notificationToastify";
import { Facebook, Youtube } from "lucide-react";
import { backDropActions } from "../../../Store/backdrop-slice";
import { FilterAction } from "../../../Store/filter-slice";
import footerImage from "../../../Images/footer_logo.png";
import { WhatsappIcon } from "react-share";

const Footer = () => {
  const footerRef = useRef(null);
  const observer = useRef(null);

  const productState = useSelector((state) => state.ProductSlice);
  const dispatch = useDispatch();
  const location = useLocation();

  const currentPath = location.pathname;

  useEffect(() => {
    const currentFooterRef = footerRef.current;

    if (!currentFooterRef || !(currentFooterRef instanceof Element)) {
      return;
    }

    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          try {
            if (
              entry.isIntersecting &&
              !productState.isProductFetch &&
              productState.data.length < productState.totalProducts &&
              currentPath === "/products"
            ) {
              dispatch(
                ProductAction.setCurrentPage({
                  currentPage: productState.currentPage + 1,
                })
              );
            }
          } catch (error) {
            toastifyError(error.message || "Please try after some time.", "🚫");
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    observer.current.observe(currentFooterRef);

    return () => {
      if (currentFooterRef && observer.current) {
        observer.current.unobserve(currentFooterRef);
      }
    };
  }, [
    dispatch,
    productState.currentPage,
    productState.data.length,
    productState.totalProducts,
    productState.isProductFetch,
    currentPath,
  ]);

  return (
    <footer className={styles.footer} ref={footerRef}>
      <div className={styles.footer_container}>
        <div className={styles.footer_container_content}>
          <div className={styles.footer_container_content_listContent}>
            <div className={styles.footer_ImageContainer}>
              <img src={footerImage} alt="Deepak Flutes" />
            </div>
            <div className={styles.socialMedia}>
              <a
                href="https://www.facebook.com/groups/2099323086991587"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialMedia_fb}
              >
                <Facebook />
              </a>
              <a
                href="https://www.youtube.com/@Deepakflutes"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialMedia_yt}
              >
                <Youtube />
              </a>
              <a
                href="https://wa.me/9928241003"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialMedia_In}
              >
                <WhatsappIcon />
              </a>
            </div>
          </div>
          <div className={styles.footer_container_content_listContent}>
            <h2>Shop</h2>
            <ul className={styles.footer_container_content_listContent_list}>
              <li
                className={
                  styles.footer_container_content_listContent_list_listItem
                }
              >
                <Link
                  to="/products"
                  onClick={() => {
                    dispatch(ProductAction.setCurrentPage({ currentPage: 1 }));
                    dispatch(backDropActions.mobileNavHandler(false));
                    dispatch(FilterAction.clearFilter());
                    dispatch(
                      FilterAction.setFilter({ materialType: "fry Bamboo" })
                    );
                  }}
                >
                  FryBamboo Flutes
                </Link>
              </li>
              <li
                className={
                  styles.footer_container_content_listContent_list_listItem
                }
              >
                <Link
                  to="/products"
                  onClick={() => {
                    dispatch(ProductAction.setCurrentPage({ currentPage: 1 }));
                    dispatch(backDropActions.mobileNavHandler(false));
                    dispatch(FilterAction.clearFilter());
                    dispatch(
                      FilterAction.setFilter({ materialType: "Bamboo" })
                    );
                  }}
                >
                  Bamboo Flutes
                </Link>
              </li>
              <li
                className={
                  styles.footer_container_content_listContent_list_listItem
                }
              >
                <Link
                  to="/products"
                  onClick={() => {
                    dispatch(ProductAction.setCurrentPage({ currentPage: 1 }));
                    dispatch(backDropActions.mobileNavHandler(false));
                    dispatch(FilterAction.clearFilter());
                    dispatch(
                      FilterAction.setFilter({ materialType: "acrylic" })
                    );
                  }}
                >
                  Acrylic Flutes
                </Link>
              </li>
              <li
                className={
                  styles.footer_container_content_listContent_list_listItem
                }
              >
                <Link
                  to="/products"
                  onClick={() => {
                    dispatch(ProductAction.setCurrentPage({ currentPage: 1 }));
                    dispatch(backDropActions.mobileNavHandler(false));
                    dispatch(FilterAction.clearFilter());
                    dispatch(
                      FilterAction.setFilter({ materialType: "Vertical" })
                    );
                  }}
                >
                  Vertical Flutes
                </Link>
              </li>
              <li
                className={
                  styles.footer_container_content_listContent_list_listItem
                }
              >
                <Link
                  to="/products"
                  onClick={() => {
                    dispatch(ProductAction.setCurrentPage({ currentPage: 1 }));
                    dispatch(backDropActions.mobileNavHandler(false));
                    dispatch(FilterAction.clearFilter());
                    dispatch(FilterAction.setFilter({ materialType: "PVC" }));
                  }}
                >
                  PVC Flutes
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles.footer_container_content_listContent}>
            <h2>Help</h2>
            <ul className={styles.footer_container_content_listContent_list}>
              <li
                className={
                  styles.footer_container_content_listContent_list_listItem
                }
              >
                <Link to="/RefundPolicy">Refund Policy</Link>
              </li>
              <li
                className={
                  styles.footer_container_content_listContent_list_listItem
                }
              >
                <Link to="/ShippingPolicy">Shipping Policy</Link>
              </li>
              <li
                className={
                  styles.footer_container_content_listContent_list_listItem
                }
              >
                <Link to="/PrivacyPolicy">Privacy Policy</Link>
              </li>
              <li
                className={
                  styles.footer_container_content_listContent_list_listItem
                }
              >
                <Link to="/TermsAndCondition">Terms and Condition</Link>
              </li>
            </ul>
          </div>
          <div className={styles.footer_container_content_listContent}>
            <h2>Contact</h2>
            <a href="tel:+9928241003">
              <p>+91 9928241003</p>
            </a>
            <a href="mailto:Deepaksoniflute@gmail.com?subject=Subject%20Here&body=Hi%20Deepak,">
              <p>deepaksoniflute@gmail.com</p>
            </a>
            <p>Deepak Flutes and Handicrafts</p>
          </div>
        </div>
        <div className={styles.footer_container_rights}>
          <h1>@2024 All right reserved. Deepak Flute</h1>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
